export enum IRoutePath {
  HOME = '/',
  FEATURES = '/features',
  SOLUTIONS = '/solutions',
  INDUSTRIES = '/industries',

  PRICING = '/pricing',
  RECOURCES = '/resources',
  COMPANY = '/company',
  COMPANY_CONTACT_US = '/company/contact',
  COMPANY_UBOUR_US = '/company/about',
  COMPANY_POLICY = '/privacy-policy',
  BLOG = '/resources/blog',
  TUTORIAL = '/resources/tutorial',
  INDUSTRIES_HVAC = '/industries/hvac',
  INDUSTRIES_PLUMBING = '/industries/plumbing',
  INDUSTRIES_HANDYMAN = '/industries/handyman',
  INDUSTRIES_APPLIANCE = '/industries/appliance-repair',
  INDUSTRIES_CONSTRUCTION = '/industries/construction',
  INDUSTRIES_ELECTRICIAN = '/industries/electrician',
  INDUSTRIES_GARAGE_DOOR = '/industries/garage-door',
  INDUSTRIES_PAINTING = '/industries/painting',
  INDUSTRIES_FIRE_SAFETY = '/industries/fire-safety',
  INDUSTRIES_CARPET_CLEANING = '/industries/carpet-cleaning',
  INDUSTRIES_HOME_CLEANING = '/industries/home-cleaning',
  INDUSTRIES_WINDOW_CLEANING = '/industries/window-cleaning',
  INDUSTRIES_LOCKSMITH = '/industries/locksmith',
  INDUSTRIES_FIELD_SERVICE = '/field-service-management-software',
  INDUSTRIES_PROPERTY_MAINTENANCE = '/industries/property-maintenance',
  FEATURES_SCHEDULIG = '/features/job-scheduling',
  FEATURES_ESTIMATES = '/features/field-service-estimating',
  FEATURES_SALES_PROPOSAL = '/features/sales-proposal-tool',
  FEATURES_DISPATCHIG = '/features/field-service-dispatching',
  FEATURES_INVOICING = '/features/field-service-invoicing',
  FEATURES_SALES = '/features/sales',
  FEATURES_PAYMENT_PROCESSING = '/features/payment-processing',
  FEATURES_GPS_TRACKING = '/features/employee-gps-tracking',
  FEATURES_REVIEW_MANAGMENT = '/features/review-management-software',
  FEATURES_ACCOUNTING = '/features/field-service-accounting',
  FEATURES_FIELD_SERVICE_MANAGMENT = '/features/field-service-mobile-app',
  FEATURES_JOB_TRACKING = '/features/job-tracking-software',
  FEATURES_TIME_TRACKING = '/features/employee-time-tracking',
  FEATURES_RECURRING_JOBS = '/features/recurring-jobs',
  FEATURES_SUPPLIES_ORDER = '/features/supplies-order',
  FEATURES_PAYMENT_MANAGEMENT = '/features/payment-management',
  FEATURES_CONSUMER_FINANCING = '/features/consumer-financing',
  FEATURES_ADVANCED_REPORTING = '/features/advanced-reporting',
  FEATURES_PRICE_PAGES = '/features/price-pages',
  FEATURES_MOBILE_APP = '/features/mobile-app',
}
