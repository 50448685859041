'use client';

import { PropsWithChildren, useState } from 'react';
import styled from 'styled-components';

import Collapse from '../Collapse/Collapse';

import { ArrowIcon } from '@/public/icons';
import { devices } from '@/styles/breakpoints';

interface AccordionProps {
  title: string;
  isOpen?: boolean;
  isNavbar?: boolean;
}

export default function Accordion({
  title,
  isOpen,
  children,
  titleStyles,
  iconStyles,
  isNavbar = false,
  descriptionStyle,
  ...rest
}: PropsWithChildren<AccordionProps>) {
  const [hasCollapsed, setHasCollapsed] = useState(!isOpen);
  const isActive = !hasCollapsed;
  return (
    <AccordionWrapper
      {...rest}
      onClick={(e) => {
        e.stopPropagation();
        setHasCollapsed((prev) => !prev);
      }}
    >
      <TitleWrapper>
        <Title className="accordeon-title" style={titleStyles}>
          {title}
        </Title>
        {isNavbar ? (
          <NavbarIcon $isactive={isActive}>
            <ArrowIcon color="#206FED" />
          </NavbarIcon>
        ) : (
          <Icon className="collapsed-icon" $isactive={isActive} {...iconStyles}>
            <ArrowIcon color="#262626" />
          </Icon>
        )}
      </TitleWrapper>
      <Collapse isOpen={isActive} duration={300}>
        <Description className="accordeon-description" style={descriptionStyle}>
          {children}
        </Description>
      </Collapse>
    </AccordionWrapper>
  );
}

const Title = styled.h3`
  font-family: var(--font-roboto);
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  width: 85%;
  padding: 16px 0;
  @media only screen and ${devices.desktop} {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    width: 95%;
    padding: 20px 0;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Icon = styled.div<{ $isactive: boolean }>`
  transition: transform 0.3s;
  transform: ${(p) => (p.$isactive ? 'rotate(0deg)' : 'rotate(-90deg)')};
  width: 40px;
  height: 40px;

  background-color: white;
  border: 1px solid #e6e8ec;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
`;

const NavbarIcon = styled.div<{ $isactive: boolean }>`
  transition: transform 0.3s;
  transform: ${(p) => (p.$isactive ? 'rotate(0deg)' : 'rotate(-90deg)')};
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
`;

const Description = styled.div`
  font-family: var(--font-roboto);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  font-weight: normal;
  width: 85%;
  color: #4d4d4d;
  @media only screen and ${devices.desktop} {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    font-weight: normal;
    width: 95%;
    margin-bottom: 20px;
  }
`;

const AccordionWrapper = styled.article`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: opacity 0.2s;
  border-bottom: 1px solid rgba(--borderGrey);

  &:last-child {
    border: none;
  }

  @media only screen and ${devices.desktop} {
    border: none;
  }
`;
