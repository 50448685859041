import { PropsWithChildren } from 'react';
import NextLink from 'next/link';

export interface LinkProps {
  href: string;
}

export default function Link({
  href,
  children,
  ...rest
}: PropsWithChildren<LinkProps>) {
  return (
    <NextLink
      style={{ textDecoration: 'none', color: 'inherit' }}
      className="navigation-link"
      href={href}
      aria-label={href}
      {...rest}
    >
      {children}
    </NextLink>
  );
}
